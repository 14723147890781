import type { Categories } from 'src/config/categories';

const config: Categories = {
  COMMERCIAL: true,
  ROOMS: false,
  NEW_BUILDING: false,
  GARAGE: true,
  PALACE: true,
  COWORKING: true,
  WAREHOUSES: true,
  SHEDS: true,
  LAND: true,
  HOTEL: false,
};

export default config;
